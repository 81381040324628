<template>
  <div class="home">
    <div class="top">
      <img class="text1" src="../assets/images/homeTxt1.png" />
      <img class="text2" src="../assets/images/homeTxt2.png" />
    </div>
    <div class="bottom">
      <div class="button" @click="next">开启挑战</div>
<!--      <img src="../assets/images/homeTxt3.png" />-->
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {};
  },
  methods: {
    next() {
      // this.$bus.$emit("playBJ", true);
      this.$router.push("/user");
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/bgHome.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .bottom {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .button {
      width: 48.5%;
      height: 52px;
      border-radius: 25px;
      background-color: #ffeccb;
      text-align: center;
      font-size: 24px;
      color: #b50b11;
      line-height: 52px;
    }
    img {
      width: 82%;
      height: auto;
      margin-top: 40px;
    }
  }

  .top {
    position: relative;
    width: 100%;
    margin-top: 84px;
    text-align: center;
    .text1 {
      width: 32.5%;
      height: auto;
    }
    .text2 {
      position: absolute;
      width: 18%;
      height: auto;
      bottom: -20px;
      margin-left: 20px;
    }
  }
}
</style>
